import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { Col, Row, Container } from "react-bootstrap"
import HeaderOne from "../components/header/header"
import Footer from "../components/footer/footer"
import CanHelp from "../components/Home/CanHelp/CanHelpStyleThree"
import ReactMarkdown from "react-markdown"
import $ from "jquery"
import { isMobile } from "react-device-detect"
import StaticBanner from "../components/Static/StaticBanner/area-guide-static-banner-ggfx-clubs"
import MGFav from "../components/Static/MgFavourites/MgUpcomingEvents"
import moment from 'moment';

const LocalLifeMainLanding = (props) => {
  //console.log("chosen_area_strapi_id",props?.location?.state?.areaName)
  const data = props.data.strapiLocalLifeCategories
  let currDate = new Date()
  // format local_life_things_to_dos data for recurring events
  let recurrevent = [];
  (data.local_life_things_to_dos).forEach((event, index) => {

    if(typeof props?.location?.state?.areaName !== "undefined" && props?.location?.state?.areaName !== null ){
      if(event?.area_guide != props?.location?.state?.areaName){
        
        return;
      }
    }
    var myarr1 = {
      ...event
    };
    //recurrevent.push(event);
    Object.assign(myarr1, {'Event_Start_Date_Time': moment(event.Event_Start_Date).format('HH:mm')});
    Object.assign(myarr1, {'Event_End_Date_Time': moment(event.Event_End_Date).format('HH:mm')});
    Object.assign(myarr1, {'Event_Start_Date_Only': moment(event.Event_Start_Date).format('YYYY-MM-DD')});
    Object.assign(myarr1, {'Event_End_Date_Only': moment(event.Event_End_Date).format('YYYY-MM-DD')});
    
    //console.log(Object.keys(event))
    if(typeof event.Recurring_Event !== "undefined" && event.Recurring_Event !== null){
      if(typeof event.Recurring_Event_Duration !== "undefined" && event.Recurring_Event_Duration > 0){
        if(event.Recurring_Event == 'Monthly'){
          for(var v = 1; v < event.Recurring_Event_Duration; v++){
            var myarr = {
              ...event
            };
            let myarrSDate = moment(myarr.Event_Start_Date).add(v, 'months').format('YYYY-MM-DD');
            let myarrEDate = moment(myarr.Event_End_Date).add(v, 'months').format('YYYY-MM-DD');
            let sdfilt = myarr.Event_Start_Date.split('T');
            let edfilt = myarr.Event_End_Date.split('T');
            let myarrSDate_new = myarrSDate+'T'+sdfilt[1];
            let myarrEDate_new = myarrEDate+'T'+edfilt[1];
            //2022-05-27T12:30:00.000Z
            delete myarr['Event_Start_Date'];
            delete myarr['Event_End_Date'];
            Object.assign(myarr, {'Event_Start_Date': myarrSDate_new});
            Object.assign(myarr, {'Event_End_Date': myarrEDate_new});
            Object.assign(myarr, {'Event_Start_Date_Only': myarrSDate});
            Object.assign(myarr, {'Event_End_Date_Only': myarrEDate});
            Object.assign(myarr, {'Event_Start_Date_Time': moment(myarr.Event_Start_Date).format('HH:mm')});
            Object.assign(myarr, {'Event_End_Date_Time': moment(myarr.Event_End_Date).format('HH:mm')});
            if( (moment(myarr.Event_Start_Date)).diff( (moment(currDate)), 'days' ) >= 0){
              recurrevent.push(myarr);
            }
            myarr = '';
          }
        }
        if(event.Recurring_Event == 'Weekly'){
          for(var v = 1; v < event.Recurring_Event_Duration; v++){
            var myarr = {
              ...event
            };
            let myarrSDate = moment(myarr.Event_Start_Date).add(v, 'weeks').format('YYYY-MM-DD');
            let myarrEDate = moment(myarr.Event_End_Date).add(v, 'weeks').format('YYYY-MM-DD');
            let sdfilt = myarr.Event_Start_Date.split('T');
            let edfilt = myarr.Event_End_Date.split('T');
            let myarrSDate_new = myarrSDate+'T'+sdfilt[1];
            let myarrEDate_new = myarrEDate+'T'+edfilt[1];
            //2022-05-27T12:30:00.000Z
            delete myarr['Event_Start_Date'];
            delete myarr['Event_End_Date'];
            Object.assign(myarr, {'Event_Start_Date': myarrSDate_new});
            Object.assign(myarr, {'Event_End_Date': myarrEDate_new});
            Object.assign(myarr, {'Event_Start_Date_Only': myarrSDate});
            Object.assign(myarr, {'Event_End_Date_Only': myarrEDate});
            Object.assign(myarr, {'Event_Start_Date_Time': moment(myarr.Event_Start_Date).format('HH:mm')});
            Object.assign(myarr, {'Event_End_Date_Time': moment(myarr.Event_End_Date).format('HH:mm')});
            if( (moment(myarr.Event_Start_Date)).diff( (moment(currDate)), 'days' ) >= 0){
              recurrevent.push(myarr);
            }
            myarr = '';
          }
        }
        if(event.Recurring_Event == 'Daily'){
          for(var v = 1; v < event.Recurring_Event_Duration; v++){
            var myarr = {
              ...event
            };
            let myarrSDate = moment(myarr.Event_Start_Date).add(v, 'days').format('YYYY-MM-DD');
            let myarrEDate = moment(myarr.Event_End_Date).add(v, 'days').format('YYYY-MM-DD');
            let sdfilt = myarr.Event_Start_Date.split('T');
            let edfilt = myarr.Event_End_Date.split('T');
            let myarrSDate_new = myarrSDate+'T'+sdfilt[1];
            let myarrEDate_new = myarrEDate+'T'+edfilt[1];
            //2022-05-27T12:30:00.000Z
            delete myarr['Event_Start_Date'];
            delete myarr['Event_End_Date'];
            Object.assign(myarr, {'Event_Start_Date': myarrSDate_new});
            Object.assign(myarr, {'Event_End_Date': myarrEDate_new});
            Object.assign(myarr, {'Event_Start_Date_Only': myarrSDate});
            Object.assign(myarr, {'Event_End_Date_Only': myarrEDate});
            Object.assign(myarr, {'Event_Start_Date_Time': moment(myarr.Event_Start_Date).format('HH:mm')});
            Object.assign(myarr, {'Event_End_Date_Time': moment(myarr.Event_End_Date).format('HH:mm')});
            if( (moment(myarr.Event_Start_Date)).diff( (moment(currDate)), 'days' ) >= 0){
              recurrevent.push(myarr);
            }
            myarr = '';
          }
        }
      }
    }
    if( (moment(myarr1.Event_Start_Date)).diff( (moment(currDate)), 'days' ) >= 0){
      recurrevent.push(myarr1);
    }
    
  });
  //console.log("visu",recurrevent)
  //recurring events end
  const cuisines = props.data.allStrapiRestaurantCuisines.edges
  const eventcategories = props.data.allStrapiEventsCategories.edges
  //const eventcontest = props.data.allStrapiGlobalModules.edges
  const [eat_in, setEatIn] = useState("")
  const [takeaways, setTakeaways] = useState("")
  const [cuisine, setCuisine] = useState("")
  const [recurreventer, setRecurreventer] = useState(recurrevent)
  const [filter_res, setFilterRes] = useState(recurreventer)
  const [isResponsive, setisResponsive] = useState(false)
  useEffect(() => {
    if ($(window).width() < 1200) {
      setisResponsive(true)
    } else {
      setisResponsive(false)
    }
    // setRecurreventer(recurrevent)
  })

  var area_list = [],
    filter_resturant = [],
    filter_resturant_cuisine = []
  for (var i = 0; i < props.data.allStrapiAreaGuides.edges.length; i++) {
    area_list.push(props.data.allStrapiAreaGuides.edges[i].node)
  }

  let areas_list = area_list.filter(
    (ele, ind) =>
      ind === area_list.findIndex(elem => elem.strapiId === ele.strapiId)
  )

  function filterTeam(event, data) {
    const eat = $(".eat:checked").val()
    const takeaways = $(".takeaways:checked").val()
    const cusines = $("#cuisines").val()
    setEatIn(eat)
    setTakeaways(takeaways)
    setCuisine(cusines)
  }

  useEffect(() => {
    if (eat_in === "eat-in" || takeaways === "takeaways") {
      filter_resturant = []

      recurreventer.map((data, index) => {
        if (
          eat_in === "eat-in" &&
          data.EatIn === true &&
          takeaways === undefined
        ) {
          filter_resturant.push(data)
        } else if (
          takeaways === "takeaways" &&
          data.Takeaways === true &&
          eat_in === undefined
        ) {
          filter_resturant.push(data)
        } else if (
          eat_in === "eat-in" &&
          takeaways === "takeaways" &&
          data.EatIn === true &&
          data.Takeaways === true
        ) {
          filter_resturant.push(data)
        }
      })

      if (cuisine !== "all") {
        filter_resturant_cuisine = []
        filter_resturant.map((data, index) => {
          if (data.Cuisine == cuisine) {
            filter_resturant_cuisine.push(data)
          }
        })
        if (filter_resturant_cuisine.length == 0) {
          setFilterRes("")
        }
        filter_resturant_cuisine &&
          filter_resturant_cuisine.length > 0 &&
          setFilterRes(filter_resturant_cuisine)
      } else {
        filter_resturant &&
          filter_resturant.length > 0 &&
          setFilterRes(filter_resturant)
      }
    } else {
      if (cuisine == "all" || cuisine == "") {
        setFilterRes(recurreventer)
      } else {
        recurreventer.map((data, index) => {
          if (data.Cuisine == cuisine) {
            filter_resturant_cuisine.push(data)
          }
        })

        if (filter_resturant_cuisine.length == 0) {
          setFilterRes("")
        }
        filter_resturant_cuisine &&
          filter_resturant_cuisine.length > 0 &&
          setFilterRes(filter_resturant_cuisine)
      }
    }
  }, [eat_in, takeaways, cuisine, recurreventer])

  let filter = "hide"

  if (data.Alias == "restaurant-and-bars") {
    filter = "show"
  } else if (data.Alias == "shops") {
    filter = "hide"
  }

  return (
    <div
      className={
        isResponsive
          ? "local-life-area-landing-page clubs-landing-page mobile-clubs-page"
          : "local-life-area-landing-page clubs-landing-page"
      }
    >
      <HeaderOne />

      {data.Banner_Image !== null && (
        <>
          <StaticBanner Title={data.Banner_Content} CTA_1_URL="test" CTA_2_URL="#" Banner_Image={data.Banner_Image} tag="local-life" area_drop_down_value={props?.location?.state?.areaName} area_drop_down={areas_list} main_url="main" id={data.strapiId} data={data} img="local-life-categories.Banner_Image.details"/>
        </>
      )}

      <Container className="intro">
        <Row>
          <Col xs={12} sm={12} lg={12}>
            <div className="content-one">
              <ReactMarkdown source={data.Intro_Content} allowDangerousHtml />
            </div>
          </Col>
          <Col xs={1}></Col>
          <Col xs={12} sm={12} lg={3} className="east-schedule">
            {filter == "show" ? (
              <div className="filter">
                <div className="cuisines">
                  <select
                    name="cuisines"
                    id="cuisines"
                    className="cuisines"
                    onChange={filterTeam}
                  >
                    <option value="all">All Cuisines</option>
                    {cuisines.map(({ node }, i) => {
                      return <option value={node.strapiId}>{node.Name}</option>
                    })}
                  </select>
                </div>
                <div className="checkbox">
                  <Row className="east-check-box">
                    <Col xs={6} className="east-filter-checkbox">
                      <p>
                        <input
                          type="checkbox"
                          value="eat-in"
                          className="eat"
                          id="eat"
                          name="Eat in"
                          onClick={filterTeam}
                          data-filter="eat_in"
                        />
                        <label for="eat">Eat in</label>
                      </p>
                    </Col>
                    <Col xs={6} className="east-filter-checkbox">
                      <p>
                        <input
                          type="checkbox"
                          value="takeaways"
                          className="takeaways"
                          id="takeaways"
                          onClick={filterTeam}
                          data-filter="takeaways"
                        />
                        <label for="takeaways">Takeaways</label>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
      {filter_res.length == 0 ? (
        <div className="no-result">
          <p>Sorry, currently there are no events</p>
        </div>
      ) : (
        <MGFav
          eventcategories={eventcategories}
          //eventcontest={eventcontest}
          data={filter_res}
          url={data.URL}
          isResponsive={isResponsive}
        />
      )}

      <CanHelp />

      <Footer Select_Popular_Search="Static_Contact" popularSearch="static" />
    </div>
  )
}

export default LocalLifeMainLanding

export const pageQuery = graphql`
  query LocalLifeAreaClubLandingQuery($slug: String!) {
    strapiLocalLifeCategories(strapiId: { eq: $slug }) {
      Banner_Content
      Intro_Content
      Meta_Description
      Meta_Title
      Name
      URL
      Alias
      strapiId
      Banner_Image {
        internal {
          description
        }
      }
      local_life_things_to_dos {
        Name
        Address
        Short_Description
        Tile_Image_Label
        URL
        EatIn
        Takeaways
        Cuisine
        Events_Category
        Select_Events_Categories
        Event_Start_Date
        Event_End_Date
        Recurring_Event
        Recurring_Event_Duration
        Tile_Image {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 210, layout: CONSTRAINED)
          }
        }
      }
      local_life_things_to_dos {
        area_guide
      }
      imagetransforms {
        Banner_Image_Transforms
      }
    }
    allStrapiAreaGuides(
      filter: {
        Local_life_things_to_dos: { elemMatch: { Category: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          Title
          URL
          strapiId
        }
      }
    }
    allStrapiEventsCategories {
      edges {
        node {
          Name
          URL
          strapiId
          local_life_things_to_dos {
            id
          }
        }
      }
    }

    allStrapiLocalLifeCategories {
      edges {
        node {
          Name
          URL
          Alias
          strapiId
          local_life_things_to_dos {
            area_guide
          }
        }
      }
    }
    allStrapiRestaurantCuisines(filter: { Publish: { eq: true } }) {
      edges {
        node {
          Name
          Alias
          strapiId
        }
      }
    }
  }
`

import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import $ from "jquery";


const UpcomingEventsCalender = (props) => {
//console.log(props.data)
  if (props.data && props.data.length > 0) {
    var dateList = []
    // var datebetween =[]

    var dateArrs = []

    function enumerateDaysBetweenDates(startDate, endDate) {
      let date = []
      while (moment(startDate) <= moment(endDate)) {
        date.push(startDate);
        startDate = moment(startDate).add(1, 'days').format("YYYY-MM-DD");
      }
      return date;
    }

    props.data.map((item, i) => {
      var startdates = item.Event_Start_Date
      var enddates = item.Event_End_Date
      dateList.push(startdates)
      dateList.push(enddates)
      dateList.push(item.Event_Start_Date_Only)
      dateList.push(item.Event_End_Date_Only)
    })

    // props.data.map((item, i) => {
    //   if (item.Event_Start_Date != item.Event_End_Date) {
    //     var dateArr = enumerateDaysBetweenDates(item.Event_Start_Date, item.Event_End_Date);   
    //     dateArr.length > 0 ? 
    //     dateArr.map(item => dateList.push(item))
    //     : dateList.push(dateArr)
    //   }
    // })
  }
  const [value, onChange] = useState(new Date());
  useEffect(() => {
    $("<p class='event-txt'>Click on a date to filter the events.</p>").insertAfter(".react-calendar__navigation");
  }, []);
  //console.log(dateList)
  return (
    <Calendar
      style={{ height: 500 }}
      onChange={onChange}
      value={value}
      tileClassName={({ date, view }) => {
        if (dateList && dateList.find(x => x === moment(date).format("YYYY-MM-DD"))) {
          return 'highlight'
        }
      }}
      //onClickDay={(value, event) => console.log('aaaaaaaaa 2',moment(value).format("YYYY-MM-DD"))}
      onClickDay={(value, event) => props.geteventdate(moment(value).format("YYYY-MM-DD"))}
      //onActiveStartDateChange={(action ) => console.log('Changed view to: ', action)}
      onActiveStartDateChange={({activeStartDate}) => {
        props.geteventdate('');props.month(moment(activeStartDate).format("MMMM YYYY"))}}
      //tileDisabled={({ date }) => date.getDay() === 0}

      /*maxDate={new Date(2020, 1, 0)}</div>*/
      minDate={
        new Date()
      }
    >
    </Calendar>
  )
}
export default UpcomingEventsCalender
